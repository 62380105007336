import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";


//componentes
import './Casos.css';
import Alert from '../../modal/alert';

class CasosAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            mensajealerta:false,
            codigo:'',
            tcaso:'',
            guardarOn:false,
            ifechamedi:[],
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    guardar=()=>{
        if (this.state.codigo.length>0 && this.state.tcaso.length>0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/maestroCasosPHP.php/?codigo='+ this.state.codigo +'&casos='+this.state.tcaso+'&boton=Agregar')
            .then(res => {
                    if (res.data.variable1) {
                        this.buscarCasos();
                        this.limpiar();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'Se Agrego correctamente el Caso',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'No se pudo agregar el Caso, verifique Gracias...',
                         })
                    }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Maestro de Casos',
                cuerpoM: 'Debe haber un Codigo y un Caso para poder Agregarlo, verifique Gracias...',
             })
        }

    }

    consulta=(e)=>{
        if (e.length>0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/maestroCasosPHP.php/?codigo='+ e +'&boton=ConsultaInd')
            .then(res => {
                    if (res.data) {
                        this.setState({
                            codigo: res.data.variable1,
                            tcaso: res.data.variable2,      
                            guardarOn:true,
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'No se encontro el Caso que se desea consultar, verifique Gracias...',
                         })
                    }
            })
        }
    }

    modificar=()=>{
        if (this.state.codigo.length>0 && this.state.tcaso.length>0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/maestroCasosPHP.php/?codigo='+ this.state.codigo +'&casos='+this.state.tcaso+'&boton=Modificar')
            .then(res => {
                    if (res.data.variable1) {
                        this.buscarCasos();
                        this.limpiar();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'Se Modifico correctamente el Caso',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'No se pudo Modificar el Caso, verifique Gracias...',
                         })
                    }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Maestro de Casos',
                cuerpoM: 'Debe haber un Codigo y un Caso para poder Modificar, verifique Gracias...',
             })
        }

    }
    eliminar=(e)=>{
        if (e.length>0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/maestroCasosPHP.php/?codigo='+ e +'&boton=Eliminar')
            .then(res => {
                    if (res.data.variable1) {
                        this.buscarCasos();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'Se Elimino correctamente el Caso',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Casos',
                            cuerpoM: 'No se pudo Eliminar el Caso, verifique Gracias...',
                         })
                    }
            })
        }
    }

    limpiar=()=>{
        this.setState({
            codigo:'',
            tcaso:'',  
            guardarOn:false,  
        })   
    }

    buscarCasos=()=>{
        axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/maestroCasosPHP.php/?codigo=&boton=Consultar')
        //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/maestroCasosPHP.php/?codigo=&boton=Consultar')
        .then(res => { 
            if (res.data) {               
                let xifechamedi = res.data
                console.log(res.data)
                this.setState({
                    ifechamedi:xifechamedi
                });
            }  
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
      }

    componentDidMount(){
       this.buscarCasos();
    }

    render(){
        return(
            <div className="cont-casos row">
                <div className="cont-casos-formulario col-12">
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }
                    <div className="row">
                        <div className="cont-titulocasos col-12">    
                            <span className="titulocasos">Maestro de Casos</span>
                            <Link to="/"><span className="titulo-cerrarcasos">X</span></Link>
                        </div>

                        <div className="cont-datoscasos col-12">
                            <div className="row">
                                <div className="cont-opcioncas col-2">
                                    <label className="opcion" >Codigo.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="codigo"
                                        id="codigo"
                                        autoComplete="off"
                                        value={this.state.codigo}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="cont-opcioncas col-8">
                                    <label className="opcion" >Tipo de Caso.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="tcaso"
                                        id="tcaso"
                                        autoComplete="off"
                                        value={this.state.tcaso}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                                <div className="botonescasos row">
                                    <div className="accionescasos col-12">
                                        <button className={this.state.guardarOn?"botoncasosOff":"botoncasos"}  disabled={this.state.guardarOn} onClick={this.guardar.bind(this)}>Agregar</button>
                                        <button className={this.state.guardarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.guardarOn} onClick={this.modificar.bind(this)}>Modificar</button>
                                        {/* <button className={this.state.guardarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.guardarOn} onClick={this.eliminar.bind(this)}>Eliminar</button> */}
                                        <button className="botoncasos" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                        <Link to="/"><button className="botoncasos">Salir</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cont-titulomedi col-12">
                            <span className="titulomedi">Listado de Casos</span>
                        </div>
                        <div className="cont-tablamedi row">
                            <div className="tablamedi1 col-7">
                                <span className="titulo-casos">Codigo</span>
                                <span className="titulo-casos">Tipo de Casos</span>
                                <span className="titulo-eliminar"><span className='cerrarme3'></span>= Eliminar</span>
                                <div className="cont-listmedi row">
                                    <div className="listmedi col-12">
                                        <table className="tablame" border="1">
                                            <tbody className="evocuerpo1">
                                                {this.state.ifechamedi.map((item, i)=>{
                                                     return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1}><td width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable1}</td>
                                                         <td width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable2}</td>                                
                                                         <td width="20"><span className='cerrarme1' onClick={this.eliminar.bind(this,item.variable1)}></span> </td></tr>                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}export default CasosAction;    
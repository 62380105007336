import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./Espera.css";
import Alert from "../../modal/alert";

class EsperaAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cedula: "",
      nombre: "",
      motivo: "",
      ifechaespera1: [],
      ifechaespera2: [],
      fechaespera: moment().format("YYYY-MM-DD"),
      clientetrue: false,
    };
    moment.locale("es");
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  cerrarlistadocli() {
    this.setState({
      ifechaevo: [],
      clientetrue: false,
    });
  }

  consultarnombre(e) {
    if (this.state.cedula.length === 0) {
      let canit = 0;
      let resul = 0;
      axios
        .post(
          "https://institutobilinguelasnaciones.com/clinicoIBLN/historia.php/?cedula=" +
            this.state.nombre +
            "&valorini=" +
            this.state.valorini +
            "&boton=Consultarnombre2"
        )
        //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/historia.php/?cedula=' + this.state.nombre + '&valorini='+ this.state.valorini +'&boton=Consultarnombre')
        .then((res) => {
          if (res.data) {
            //console.log("paso:" +res.data)

            let xfechaevo = res.data;
            //canit = xfechaevo.map(p => p.variable4);
            canit = xfechaevo.slice(0, 1).map((p) => p.variable4);
            if (parseFloat(canit) / 20 > Math.round(parseFloat(canit) / 20)) {
              resul = Math.round(parseInt(canit) / 20) + 1;
            } else {
              resul = Math.round(parseInt(canit) / 20);
            }

            this.setState({
              cantitem: canit,
              cantpagi: resul,
              ifechaevo: xfechaevo,
              clientetrue: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Historia Paciente",
              cuerpoM:
                "No se encontraron pacientes con esos datos, verifique Gracias.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Historia Paciente",
        cuerpoM: "No se encontraron pacientesDebe Gracias.",
      });
    }
  }

  consultar(e) {
    if (this.state.cedula.length > 0) {
      axios
        .post(
          "https://institutobilinguelasnaciones.com/clinicoIBLN/historia.php/?cedula=" +
            this.state.cedula +
            "&boton=Consultar"
        )
        .then((res) => {
          if (res.data) {
            this.setState({
              nombre: res.data.variable3 + " " + res.data.variable4,
              clientetrue: false,
            });
            this.buscarcitaspacientes();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Historia Paciente",
              cuerpoM:
                "No se encontro la cedula que se desea consultar, verifique Gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Historia Paciente",
        cuerpoM:
          "Debe Introducir un numero de cedula para poder realizar una consulta Gracias...",
      });
    }
  }

  consultar2(e) {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/clinicoIBLN/historia.php/?cedula=" +
          e +
          "&boton=Consultar"
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            cedula: res.data.variable1,
            nombre: res.data.variable3 + " " + res.data.variable4,
            clientetrue: false,
          });
          this.buscarcitaspacientes();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Historia Paciente",
            cuerpoM:
              "No se encontro la cedula que se desea consultar, verifique Gracias...",
          });
        }
      });
  }

  guardar = () => {
    if (parseFloat(this.state.cedula) > 0) {
      let fech1 =
        moment(this.state.fechaespera).format("YYYYMMDD") + this.state.cedula;
      axios
        .post(
          "https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo=" +
            fech1 +
            "&cedula=" +
            this.state.cedula +
            "&motivo=" +
            this.state.motivo +
            "&fecha=" +
            this.state.fechaespera +
            "&boton=Agregar"
        )
        //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+fech1+'&cedula='+this.state.cedula+'&motivo='+this.state.motivo+'&fecha='+this.state.fechaespera+'&boton=Agregar')
        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Lista de Espera",
              cuerpoM: "Se guardo la fecha para la cita del Paciente",
            });
            this.buscarpacientesespera();
            this.buscarcitaspacientes();
          } else {
            if (res.data.variable2 === "correono") {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Historia Paciente",
                cuerpoM:
                  "El Paciente no tiene correo, debera agregarle uno y luego agregarlo a la lista de espera, Gracias.",
              });
            } else {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Historia Paciente",
                cuerpoM:
                  "El Paciente ya tiene la cita registrada para la fecha que intenta incluir verifique por favor, verifique Gracias...",
              });
            }
          }
        });
    }
  };

  limpiar = () => {
    this.setState({
      cedula: "",
      nombre: "",
      motivo: "",
      ifechaespera1: [],
      ifechaespera2: [],
      fechaespera: moment().format("YYYY-MM-DD"),
    });
    this.buscarpacientesespera();
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Eliminar"
        )
        //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Eliminar')
        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Lista de Espera",
              cuerpoM:
                "La cita del paciente para esta fecha fue eliminada con exito",
            });
            this.buscarpacientesespera();
            this.buscarcitaspacientes();
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Lista de Espera",
              cuerpoM:
                "Ocuriio un error, no se pudo eliminar la cita del paciente, verifique por favor",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Lista de Espera",
        cuerpoM:
          "Ocuriio un error, El Paciente no tiene Id ponerse en contacto con institutobilinguelasnaciones Computer Center",
      });
    }
  };

  buscarcitaspacientes = () => {
    if (this.state.cedula.length > 0) {
      axios
        .post(
          "https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo=&cedula=" +
            this.state.cedula +
            "&motivo=&fecha=&boton=Buscarpaciente"
        )
        .then((res) => {
          if (res.data) {
            let xifechaespera2 = res.data;
            this.setState({
              ifechaespera2: xifechaespera2,
            });
          }
        });
    }
  };

  buscarpacientesespera = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo=&cedula=&motivo=&fecha=" +
          this.state.fechaespera +
          "&boton=Buscar"
      )
      .then((res) => {
        if (res.data) {
          let xifechaespera1 = res.data;
          this.setState({
            ifechaespera1: xifechaespera1,
          });
        } else {
          this.setState({
            ifechaespera1: [],
          });
        }
      });
  };

  getButtons = () => {
    let botones = [];
    let bandera = 0;
    //console.log(this.state.cantpagi+"-"+this.state.paginactiva)
    botones.push(
      <button
        className={
          parseInt(this.state.paginactiva) > 10
            ? "botonspanOf"
            : "botonspanocultar"
        }
        name="izquierdaS"
        onClick={this.busquedapagi}
      >
        &lt;
      </button>
    );

    for (let i = 0; i < this.state.cantpagi; i++) {
      bandera = 0;
      if (
        i + 1 >= parseInt(this.state.desde) &&
        i + 1 <= parseInt(this.state.hasta)
      ) {
        bandera = 1;
      }
      botones.push(
        <button
          className={
            parseInt(this.state.paginactiva) === i + 1
              ? "botonspanOf"
              : "botonspan"
          }
          name={parseInt(i) + 1}
          onClick={this.busquedapagi}
          id={bandera === 0 ? "ocultar" : ""}
        >
          {parseInt(i) + 1}
        </button>
      );
    }

    botones.push(
      <button
        className={
          parseInt(this.state.cantpagi) > 10
            ? "botonspanOf"
            : "botonspanocultar"
        }
        name="derechaS"
        onClick={this.busquedapagi}
      >
        &gt;
      </button>
    );
    botones.push(
      <button
        className={
          parseInt(this.state.cantpagi) > 10
            ? "botonspanOf"
            : "botonspanocultar"
        }
        name="derechaD"
        onClick={this.busquedapagi}
      >
        &gt;&gt;
      </button>
    );

    return botones;
  };

  busquedapagi = (e) => {
    let spaso = 0;
    let numepag = 0;
    let desde1 = 0;
    let hasta1 = 0;
    let resulpaghasta = 0;
    let resulpagdesde = 0;
    let difehasta = 0;
    let paso = 0;

    if (e.target.name === "izquierdaS") {
      spaso = 1;
      numepag = parseInt(this.state.paginactiva) - 1;
      if (numepag === 0) numepag = 1;
    }
    if (e.target.name === "derechaS") {
      spaso = 1;
      numepag = parseInt(this.state.paginactiva) + 1;
      if (numepag > parseInt(this.state.cantpagi))
        numepag = parseInt(this.state.cantpagi);
    }
    if (e.target.name === "derechaD") {
      spaso = 1;
      numepag = parseInt(this.state.cantpagi);
    }

    if (parseInt(spaso) === 0) {
      numepag = parseInt(e.target.name);
    }

    resulpaghasta = parseInt(this.state.cantpagi) - numepag;

    if (resulpaghasta >= 4) {
      hasta1 = numepag + 4;
      difehasta = 0;
    }
    if (resulpaghasta < 4 && resulpaghasta > 0) {
      hasta1 = numepag + resulpaghasta;
      difehasta = 4 - resulpaghasta;
    }
    if (resulpaghasta <= 0) {
      hasta1 = numepag;
      difehasta = 4;
    }
    resulpagdesde = numepag - 1;

    if (resulpagdesde <= 0) desde1 = 1;

    if (resulpagdesde < 5 && resulpagdesde > 0) {
      if (difehasta > 0) {
        if (resulpagdesde <= difehasta) {
          desde1 = numepag - resulpagdesde;
          resulpagdesde = 0;
        }
        if (resulpagdesde > difehasta) {
          desde1 = numepag - difehasta;
          resulpagdesde = resulpagdesde - difehasta;
        }
      }
      if (difehasta <= 0) desde1 = numepag;
    }
    if (resulpagdesde < 5 && resulpagdesde > 0) desde1 = desde1 - resulpagdesde;

    if (resulpagdesde >= 5) {
      if (difehasta > 0) {
        desde1 = numepag - difehasta;
        resulpagdesde = resulpagdesde - difehasta;
        paso = 1;
      }
      if (difehasta <= 0) desde1 = numepag - 5;

      if (resulpagdesde >= 5 && paso === 1) desde1 = desde1 - 5;
      if (resulpagdesde >= 1 && resulpagdesde < 5 && paso === 1)
        desde1 = desde1 - resulpagdesde;
    }

    if (resulpagdesde < 5) hasta1 = hasta1 + (5 - resulpagdesde);

    axios
      .post(
        "https://institutobilinguelasnaciones.com/clinicoIBLN/historia.php/?cedula=" +
          this.state.nombre +
          "%&valorini=" +
          numepag +
          "&boton=Consultarnombre"
      )
      //if (numepag===2) console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/historia.php/?cedula=' + this.state.nombre + '%&valorini='+ numepag +'&boton=Consultarnombre')
      .then((res) => {
        if (res.data) {
          let xfechaevo = res.data;
          this.setState({
            ifechaevo: xfechaevo,
            clientetrue: true,
            paginactiva: numepag,
            desde: desde1,
            hasta: hasta1,
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarpacientesespera();
  }

  render() {
    return (
      <div className="cont-principal-espera row">
        <div className="cont-espera col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.clientetrue === true && (
            <div className="cont-listadocli col-8">
              <div className="col-12">
                <div className="cont-p row">
                  <div className="cedula-paciente col-1">
                    <label>Cédula.</label>
                  </div>
                  <div className="nombre-paciente col-8">
                    <label>Nombre del Paciente.</label>
                  </div>
                  <div className="cont-cerrar col-2">
                    <span
                      className="cerrar"
                      onClick={this.cerrarlistadocli.bind(this)}
                    >
                      X
                    </span>
                  </div>
                </div>
              </div>
              <div className="listadocli col-8">
                <table className="tabla2">
                  <tbody className="cuerpo1">
                    {this.state.ifechaevo.map((item, i) => {
                      //console.log("paso "+i);

                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={item.variable1}
                          onClick={this.consultar2.bind(this, item.variable1)}
                        >
                          <td width="30px" key={item.variable1}>
                            {item.variable1}
                          </td>
                          <td width="150px">
                            {item.variable2 + " " + item.variable3}
                          </td>
                          <td width="25px">{item.variable5}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="cont-botonce col-5">{this.getButtons()}</div>
                </div>
              </div>
            </div>
          )}
          <div className="cont-titulo-espera row">
            <div className="col-12">
              <span className="tituloespera">Menu Listado Espera.</span>
            </div>
            <div className="cont-datos-espera col-12">
              <div className="row">
                <div className="cont-cedula col-3">
                  <label className="opcion">Cedula.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="cedula"
                    id="cedula"
                    autoComplete="off"
                    value={this.state.cedula}
                    onChange={this.onChange.bind(this)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.consultar();
                      }
                    }}
                  />
                  <i
                    className="icono-izquierdac fa fa-search"
                    onClick={this.consultar.bind(this)}
                  >
                    {" "}
                  </i>
                </div>
                <div className="cont-cedula col-5">
                  <label className="opcion">Nombre.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="nombre"
                    id="nombre"
                    autoComplete="off"
                    value={this.state.nombre}
                    onChange={this.onChange.bind(this)}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.consultarnombre();
                      }
                    }}
                  />
                                    <i
                    className="icono-izquierdac fa fa-search"
                    onClick={this.consultarnombre.bind(this)}
                  >
                    {" "}
                  </i>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="cont-motivo-consulta row">
                <div className="cont-motivo col-5">
                  <label className="opcion">Motivo Consulta.</label>
                  <input
                    className="form-control"
                    type="text"
                    name="motivo"
                    id="motivo"
                    autoComplete="off"
                    value={this.state.motivo}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
                <div className="cont-fecha col-2">
                  <input
                    className="form-control"
                    name="fechaespera"
                    id="fechaespera"
                    type="date"
                    value={moment(this.state.fechaespera).format("YYYY-MM-DD")}
                    onChange={this.onChange.bind(this)}
                  />
                </div>
                <div className="col-1">
                  <i
                    className="icono-izquierdaespera fa fa-search"
                    onClick={this.buscarpacientesespera.bind(this)}
                  ></i>
                </div>

                <div className="acciones col-4">
                  <button className="boton" onClick={this.guardar.bind(this)}>
                    Guardar
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="botonmedi">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cont-principal-tablas col-12">
          <div className="row">
            <div className="cont-general-citas col-9">
              <span className="cont-span-cita">
                Citas a la Fecha Seleccionada
              </span>
              <div className="listadocasos">
                <table className="tablaespera1">
                  <thead>
                    <tr>
                      <th className="tituevo1">Nombre</th>
                      <th className="tituevo1">Motivo</th>
                      <th className="tituevo1">
                        <span className="cerrarme3"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cuerpoespera1">
                    {this.state.ifechaespera1.map((item, i) => {
                      //console.log("paso "+i);

                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={item.variable1}
                        >
                          <td
                            width="150"
                            className="itemtabla1"
                            key={item.variable1}
                          >
                            {item.variable2}
                          </td>
                          <td width="250" className="itemtabla1">
                            {item.variable3}
                          </td>
                          <td width="20">
                            <span
                              className="cerrarme2"
                              onClick={this.eliminar.bind(this, item.variable1)}
                            ></span>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="cont-general-citas col-3">
              <span className="cont-span-cita">Citas del Paciente</span>
              <div className="listadocasos">
                <table className="tablaespera1">
                  <thead>
                    <tr>
                      <th className="tituevo1">Fecha</th>
                    </tr>
                  </thead>
                  <tbody className="cuerpoespera1">
                    {this.state.ifechaespera2.map((item, i) => {
                      //console.log("paso "+i);
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : ""}
                          key={item.variable1}
                        >
                          <td
                            width="150"
                            className="itemtabla2"
                            key={item.variable1}
                          >
                            {item.variable2}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EsperaAction;

// Dependencias
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { saveCliente, saveToken} from '../accion/accion';

// componentes
import AppRoutes from '../routes';
import Header from './Header/';
import SideBar from './SideBar/';

//css
import './App.css'
// import Chat from './Chat'


class AppPrincipal extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  componentDidMount() {
    let tokenp=undefined;
    let username=undefined;
    let clav=undefined;
    let nivel=undefined;
    this.props.saveToken(tokenp);
    this.props.saveCliente(username,clav, nivel);
  }

      
    render() {
        //console.log(this.props.location.pathname)
    
      return (
        <div className="conte">
          
          <div className="contenedor-principal row">
            <div className="cont-princi col-12">
              {this.props.location.pathname!=='/informepaciente' &&
                <div className="cont-sider col-2">
                  <SideBar />
                </div>
              }
              {this.props.location.pathname!=='/informepaciente' &&
               <div className="cont-header col-10">
                  <div className="col-12">
                   <Header />  
                  </div>
                <div className="cont-contenedor">
                  <div className="card col-12">
                    <AppRoutes />
                  </div>
                </div>
              </div>
              }
              {this.props.location.pathname==='/informepaciente' &&
               <div className="cont-header col-12">
                <div className="cont-contenedor">
                  <div className="card col-12">
                    <AppRoutes />
                  </div>
                </div>
              </div>
              }

            </div>       
          {/* <Footer copyright = "@copy; React 2018"/> */}
          </div>
          {/* <Chat /> */}
        </div>
      );
    }
}


const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AppPrincipal));
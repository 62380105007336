import React, { Component } from 'react';

//componentes
import HorasDiasEAction from './horasDiasE'

class HorasDiasE extends Component {

    render(){
        return(
            <div>
                <HorasDiasEAction />
            </div>
        );
    }

}
export default HorasDiasE;
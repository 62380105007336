import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./horasDiasE.css";
import Alert from "../../modal/alert";

class HorasAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      mensajealerta: false,
      codigo: "",
      tcaso: "",
      guardarOn: false,
      ifechamedi: [],

      especialista: "",
      iespecialista: [],
      horario: "",
      ihorario: [],
      diahorio: "",
      iasociado: [],
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      tcaso: "",
      guardarOn: false,

      especialista: "",
      horario: "",
      diahorio: "",
      iasociado: [],
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange1(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consulta(e.target.value);
  }

  guardar = () => {
    if (
      this.state.especialista !== "" &&
      this.state.horario !== "" &&
      this.state.diahorio !== ""
    ) {
      let data = new FormData();
      data.append("idespecialista", this.state.especialista);
      data.append("idhora", this.state.horario);
      data.append("dia", this.state.diahorio);
      data.append("boton", "Grabar");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/clinicoIBLN/horariosEspecialistas.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consulta(this.state.especialista);
            //this.limpiar();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Especialistas.",
              cuerpoM: "Se Agrego correctamente el Caso",
            });
          } else {
            this.setState({
              consulOn: true,
              mensajealerta: true,
              tituloM: "Menu Asociar Especialistas.",
              cuerpoM: "Ya existe el Registro, Verifique por Favor.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asociar Especialistas.",
        cuerpoM: "Debe seleccionar las opciones que se le piden.",
      });
    }
  };

  consulta = (e) => {
    this.setState({
      iasociado: [],
    });
    let data = new FormData();
    data.append("idespecialista", e);
    data.append("boton", "Buscar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/clinicoIBLN/horariosEspecialistas.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data) {
          let xiasociado = res.data;
          this.setState({
            iasociado: xiasociado,
          });
        }
      });
  };

  eliminar = (e) => {
    let data = new FormData();
    data.append("idhorasDias", e);
    data.append("boton", "Eliminar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/clinicoIBLN/horariosEspecialistas.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consulta(this.state.especialista);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asociar Especialistas.",
            cuerpoM: "Se elimino con exito el Registro.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asociar Especialistas.",
            cuerpoM: "No Se Pudo Eliminar el Registro, verifique por favor.",
          });
        }
      });
  };

  horarios = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/clinicoIBLN/horarios.php/?codigo=&boton=Consultar"
      )
      //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/horarios.php/?codigo=&boton=Consultar')
      .then((res) => {
        if (res.data) {
          let xifechamedi = res.data;
          console.log(res.data);
          this.setState({
            ihorario: xifechamedi,
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarMedicos = () => {
    let data = new FormData();
    data.append("boton", "Buscar");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/clinicoIBLN/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data) {
          let xifechaespera1 = res.data;
          this.setState({
            iespecialista: xifechaespera1,
          });
        } else {
          this.setState({
            iespecialista: [],
          });
        }
      });
  };

  imprimirHorario = () => {
    let data = new FormData();
    data.append("idespecialista", this.state.especialista);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/clinicoIBLN/fpdf/reportes/reporteHorarioEspecialista.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
       window.open(fileURL);
      // let link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download = "reporte.pdf";
      // link.click();
    });
  };

  componentDidMount() {
    this.buscarMedicos();
    this.horarios();
  }

  render() {
    return (
      <div className="cont-casos row">
        <div className="cont-casos-formulario col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="row">
            <div className="cont-titulocasos col-12">
              <span className="titulocasos">
                Asociar Horas a Especialistas.
              </span>
              <Link to="/">
                <span className="titulo-cerrarcasos">X</span>
              </Link>
            </div>

            <div className="cont-datoscasos col-12">
              <div className="row">
                <div className="cont-opcion col-4">
                  <label className="opcion">Especialista</label>
                  <select
                    id="especialista"
                    name="especialista"
                    value={this.state.especialista}
                    onChange={this.onChange1.bind(this)}
                  >
                    <option></option>
                    {this.state.iespecialista.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable3}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="cont-opcion col-2">
                  <label className="opcion">Seleccione horario .</label>
                  <select
                    id="horario"
                    name="horario"
                    value={this.state.horario}
                    onChange={this.onChange.bind(this)}
                  >
                    <option></option>
                    {this.state.ihorario.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="cont-opcion col-2">
                  <label className="opcion">Seleccione Dia.</label>
                  <select
                    id="diahorio"
                    name="diahorio"
                    value={this.state.diahorio}
                    onChange={this.onChange.bind(this)}
                  >
                    <option></option>
                    <option value="1">Lunes</option>
                    <option value="2">Martes</option>
                    <option value="3">Miercoles</option>
                    <option value="4">Jueves</option>
                    <option value="5">Viernes</option>
                  </select>
                </div>

                <div className="botonescasos row">
                  <div className="accionescasos col-12">
                    <button
                      className={
                        this.state.guardarOn ? "botoncasosOff" : "botoncasos"
                      }
                      disabled={this.state.guardarOn}
                      onClick={this.guardar.bind(this)}
                    >
                      Agregar
                    </button>
                    {this.state.especialista.length > 0 && (
                      <button
                        className="botoncasos"
                        onClick={this.imprimirHorario.bind(this)}
                      >
                        Imprimir Horario
                      </button>
                    )}
                    {/* <button
                      className={
                        this.state.guardarOn ? "botoncasos" : "botoncasosOff"
                      }
                      disabled={!this.state.guardarOn}
                      onClick={this.modificar.bind(this)}
                    >
                      Modificar
                    </button> */}
                    {/* <button className={this.state.guardarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.guardarOn} onClick={this.eliminar.bind(this)}>Eliminar</button> */}
                    <button
                      className="botoncasos"
                      onClick={this.limpiar.bind(this)}
                    >
                      Limpiar
                    </button>
                    <Link to="/">
                      <button className="botoncasos">Salir</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="cont-titulomedi col-12">
              <span className="titulomedi">Lista de Horas</span>
            </div>
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-7">
                {/* <span className="titulo-casos">Especialista</span> */}
                <span className="titulo-casos">Horario</span>
                <span className="titulo-casosh">Día</span>
                <span className="titulo-eliminar">
                  <span className="cerrarme3"></span>= Eliminar
                </span>
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.iasociado.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                            >
                              <td width="20">{item.variable2}</td>
                              <td width="20">{item.variable3}</td>
                              <td width="20">
                                <span
                                  className="cerrarme1"
                                  onClick={this.eliminar.bind(
                                    this,
                                    item.variable1
                                  )}
                                ></span>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HorasAction;

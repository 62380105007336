import React, {Component}  from 'react';
import moment from "moment";
import axios from 'axios';
// import Countdown from 'react-countdown-now';

import './QuimioCiclo.css';
import Alert from '../../modal/alert'

class Quimioci extends Component {
  constructor(props){
    super(props);
    this.state = {
        cedula: this.props.cedupa,
        nombre: this.props.nombrepa,
        edad: this.props.edadpa,
        fecha: moment().format('YYYY-MM-DD'),
        diagnostico:'',
        protocolo:'',
        peso:'',
        talla:'',
        sc:'',
        cc:'',
        mm:'',
        ll:'',
        ciclo:'',
        fechaci:'',
        observacion:'',
        preme:'',
        quimiot:'',
        mensajealerta:false,
    }
  }
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }
    guardar=()=>{
        if (parseFloat(this.state.cedula) >0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/quimiomensualnuevoPHP.php/?cedu='+this.state.cedula+'&edad='+this.state.edad+'&nomb='+this.state.nombre+'&fechap='+this.state.fecha+'&diagnostico='+this.state.diagnostico+'&protocolo='+this.state.protocolo+'&peso='+this.state.peso+'&talla='+this.state.talla+'&fechap='+this.state.fecha+'&sc='+this.state.sc+'&cc='+this.state.cc+'&mm='+this.state.mm+'&ll='+this.state.ll+'&ciclon='+this.state.ciclo+'&fechaci='+this.state.fechaci+'&premedicacion='+this.state.preme+'&quimioterapia='+this.state.quimiot+'&observacion='+this.state.observacion+'&boton=Guardar')
            //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+fech1+'&cedula='+this.state.cedula+'&motivo='+this.state.motivo+'&fecha='+this.state.fechaespera+'&boton=Agregar')
            .then(res => {
                if (res.data.variable1) {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Quimio X Ciclo',
                        cuerpoM: 'Se guardo la ficha del ciclo con exito',
                    })
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Quimio X Ciclo',
                        cuerpoM: 'No se guardo la ficha verifique por favor...',
                    })
                }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Quimio X Ciclo',
                cuerpoM: 'Debe seleccionar un paciente',
            })
        }
    }

    consultar=()=>{
        if (parseFloat(this.state.cedula) >0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/quimiomensualnuevoPHP.php/?cedu='+this.state.cedula+'&boton=Consultar')
            .then(res => {
                if (res.data.variable1) {
                    
                    this.setState({
                        nombre: this.props.nombrepa,
                        edad: this.props.edadpa,
                        fecha: res.data.variable2,
                        diagnostico: res.data.variable3,
                        protocolo: res.data.variable4,
                        peso: res.data.variable5,
                        talla: res.data.variable6,
                        sc: res.data.variable7,
                        cc: res.data.variable8,
                        mm: res.data.variable9,
                        ll: res.data.variable10,
                        ciclo: res.data.variable11,
                        fechaci: res.data.variable12,
                        preme: res.data.variable13,
                        quimiot: res.data.variable14,
                        observacion: res.data.variable15,                    })
                }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Quimio X Ciclo',
                cuerpoM: 'Debe seleccionar un paciente',
            })
        }     
}

imprimir=()=>{
    
    let data = new FormData();
    data.append('cedu', this.state.cedula);
    data.append('edad', this.state.edad);
    data.append('nomb', this.state.nombre);
    data.append('fechap', this.state.fecha);
    data.append('diagnostico', this.state.diagnostico);
    data.append('protocolo', this.state.protocolo);
    data.append('peso', this.state.peso);
    data.append('talla', this.state.talla);
    data.append('fechap', this.state.fecha);
    data.append('sc', this.state.sc);
    data.append('cc', this.state.cc);
    data.append('mm', this.state.mm);
    data.append('ll', this.state.ll);
    data.append('ciclon', this.state.ciclo);
    data.append('fechaci', this.state.fechaci);
    data.append('premedicacion', this.state.preme);
    data.append('quimioterapia', this.state.quimiot);
    data.append('observacion', this.state.observacion);
    
//    let config = {
//     headers: {
//         'responseType':'arraybuffer',}
//   };
//    const xurl = 'https://institutobilinguelasnaciones.com/clinicoIBLN/grabarSonido.php';
//    axios.post(xurl, data, config)    


    //axios.post('https://institutobilinguelasnaciones.com/clinica/fpdf/reportes/reporquimiomenuevoPHP.php/?cedu='+this.state.cedula+'&edad='+this.state.edad+'&nomb='+this.state.nombre+'&fechap='+this.state.fecha+'&diagnostico='+this.state.diagnostico+'&protocolo='+this.state.protocolo+'&peso='+this.state.peso+'&talla='+this.state.talla+'&fechap='+this.state.fecha+'&sc='+this.state.sc+'&cc='+this.state.cc+'&mm='+this.state.mm+'&ll='+this.state.ll+'&ciclon='+this.state.ciclo+'&fechaci='+this.state.fechaci+'&premedicacion='+this.state.preme+'&quimioterapia='+this.state.quimiot+'&observacion='+this.state.observacion, responseType='arraybuffer')
//    console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/fpdf/reportes/reporquimiomenuevoPHP.php/?cedu='+this.state.cedula+'&edad='+this.state.edad+'&nomb='+this.state.nombre+'&fechap='+this.state.fecha+'&diagnostico='+this.state.diagnostico+'&protocolo='+this.state.protocolo+'&peso='+this.state.peso+'&talla='+this.state.talla+'&fechap='+this.state.fecha+'&sc='+this.state.sc+'&cc='+this.state.cc+'&mm='+this.state.mm+'&ll='+this.state.ll+'&ciclon='+this.state.ciclo+'&fechaci='+this.state.fechaci+'&premedicacion='+this.state.preme+'&quimioterapia='+this.state.quimiot+'&observacion='+this.state.observacion+'&boton=Guardar')

axios({
        method:'post',
        url:'https://institutobilinguelasnaciones.com/clinicoIBLN/fpdf/reportes/reporquimiomenuevoPHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        //   let link = document.createElement('a')
        //   link.href = window.URL.createObjectURL(blob)
        //   link.download = 'reporte.pdf'
        //   link.click()
         
      });

    // axios.post('https://institutobilinguelasnaciones.com/clinica/fpdf/reportes/infocartaPHP.php')
    // .then(res => {
    //     console.log(res.data)
     
    // })    

}

handleClose = () => {
    this.setState({ mensajealerta: false })
  }

componentDidMount(){
    this.consultar();
}

  render(){
      return(
        <div className="cont-quimioc col-12">

            {this.state.mensajealerta === true &&
                <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
                />
            }  

            <div className="row">
                <div className="cont-tituloquimioc col-12">    
                    <span className="tituloquimioc">Quimio por Ciclos</span>
                    <span className="titulo-cerrarquimioc" onClick={this.props.handleQuimioc}>X</span>
                </div>
            </div>
        
            <div className='row'>
                <div className="cont-opcion col-3">
                    <label className="opcion" >Cedula.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="cedula"
                        id="cedula"
                        onChange={this.onChange.bind(this)}
                        value={this.state.cedula}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >Edad.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="edad"
                        id="edad"
                        onChange={this.onChange.bind(this)}
                        value={this.state.edad}
                    />
                </div>
                <div className="cont-opcion col-6">
                    <label className="opcion" >Nombre.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="nombre"
                        id="nombre"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.nombre}
                    />
                </div>
                <div className="cont-opcion col-2">
                    <label className="opcion" >Fecha Planificacion.</label>
                    <input  
                        className="form-control"  
                        name="fecha"
                        id="fecha"
                        type="date" 
                        value={ moment(this.state.fecha).format("YYYY-MM-DD") } 
                        onChange={ this.onChange.bind(this) } 
                    />
                </div>
            </div>    
            <div className='row'>
                <div className="cont-opcion col-6">
                    <label className="opcion" >Diagnostico.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="diagnostico"
                        id="diagnostico"
                        onChange={this.onChange.bind(this)}
                        value={this.state.diagnostico}
                    />
                </div>
                <div className="cont-opcion col-6">
                    <label className="opcion" >Protocolo.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="protocolo"
                        id="protocolo"
                        onChange={this.onChange.bind(this)}
                        value={this.state.protocolo}
                    />
                </div>
            </div>    
            <div className='row'>
                <div className="cont-opcion col-1">
                    <label className="opcion" >Peso.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="peso"
                        id="peso"
                        onChange={this.onChange.bind(this)}
                        value={this.state.peso}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >Talla.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="talla"
                        id="talla"
                        onChange={this.onChange.bind(this)}
                        value={this.state.talla}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >SC.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="sc"
                        id="sc"
                        onChange={this.onChange.bind(this)}
                        value={this.state.sc}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >C.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="cc"
                        id="cc"
                        onChange={this.onChange.bind(this)}
                        value={this.state.cc}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >M.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="mm"
                        id="mm"
                        onChange={this.onChange.bind(this)}
                        value={this.state.mm}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >L.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="ll"
                        id="ll"
                        onChange={this.onChange.bind(this)}
                        value={this.state.ll}
                    />
                </div>
                <div className="cont-opcion col-1">
                    <label className="opcion" >Ciclo No.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="ciclo"
                        id="ciclo"
                        onChange={this.onChange.bind(this)}
                        value={this.state.ciclo}
                    />
                </div>
                <div className="cont-opcion col-2">
                    <label className="opcion" >Fecha.</label>
                    <input
                        className="form-control"
                        name="fechaci"
                        id="fechaci"
                        type="date" 
                        value={ moment(this.state.fechaci).format("YYYY-MM-DD") } 
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>    
            <div className='row'>
                <div className="cont-opcion col-6">
                    <label className="opcion" >Premedicación.</label>
                    <textarea
                        className="form-control"
                        name="preme"
                        id="preme"
                        rows="3"
                        cols="135"
                        value={this.state.preme}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
                <div className="cont-opcion col-6">
                    <label className="opcion" >Quimio Terapia.</label>
                    <textarea
                        className="form-control"
                        name="quimiot"
                        id="quimiot"
                        rows="3"
                        cols="135"
                        value={this.state.quimiot}
                        onChange={this.onChange.bind(this)}
                    />
                </div>
            </div>

            <div className='row'>
                <div className="cont-opcion col-6">
                    <label className="opcion" >Observacion.</label>
                    <input
                        className="form-control"
                        type="text"
                        name="observacion"
                        id="observacion"
                        onChange={this.onChange.bind(this)}
                        value={this.state.observacion}
                    />
                </div> 
            </div>

            
    
        <div className="row">
            <div className="botonesgeneral col-12">
                <button className="botongr" onClick={this.guardar.bind(this)}>Guardar</button>
                <button className="botongr" onClick={this.imprimir.bind(this)}>Imprimir</button>
            </div>
        </div>
    </div>
)
  }
}
export default Quimioci;  
import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import moment from "moment";


//componentes
import './Citas.css';
import Alert from '../../modal/alert'

class CitasAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codigo:'',
            cedula:'',
            nombre:'',
            motivo:'',
            ordendellegada:'',
            ifechaespera1:[],
            fechaespera: moment().format('YYYY-MM-DD'),
        }
        moment.locale('es');
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    consultar(e) {
        if (parseFloat(e) >0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
            //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
            .then(res => {
                if (res.data) {
                    this.setState({
                        codigo: res.data.variable1,
                        cedula: res.data.variable2,
                        nombre: res.data.variable3, 
                        motivo: res.data.variable4,
                        ordendellegada: res.data.variable5,
                    })
                }
            })
        } 
    }

    guardar=()=>{
        if (parseFloat(this.state.codigo) >0) {
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+this.state.codigo+'&orden='+this.state.ordendellegada+'&cedula=&motivo=&fecha=&boton=Modificar')
            .then(res => {
                if (res.data.variable1) {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Lista de Espera',
                        cuerpoM: 'Se actualizo la orden de llegada del paciente',
                    })
                }
                this.limpiar();
                this.buscarpacientesespera();
            })
        }
    }

    limpiar=()=>{
        this.setState({
            codigo:'',
            cedula:'',
            nombre:'',
            motivo:'',
            ordendellegada:'',
        })

    }
    
    eliminar=(e)=>{
        if (parseFloat(e)>0){
            axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Eliminar')
            //console.log('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Eliminar')
            .then(res => {
                if (res.data.variable1) {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Lista de Espera',
                        cuerpoM: 'La cita del paciente para esta fecha fue eliminada con exito',
                    })
                    this.buscarpacientesespera();
                }else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Lista de Espera',
                        cuerpoM: 'Ocuriio un error, no se pudo eliminar la cita del paciente, verifique por favor',
                    })
                } 
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Lista de Espera',
                cuerpoM: 'Ocuriio un error, El Paciente no tiene Id ponerse en contacto con institutobilinguelasnaciones Computer Center',
            })
        } 

    }
    

    buscarpacientesespera=()=>{
        axios.post('https://institutobilinguelasnaciones.com/clinicoIBLN/ingresaresperaPHP.php/?codigo=&cedula=&motivo=&fecha=' +  this.state.fechaespera + '&boton=BuscarEspera')
        .then(res => {
            if (res.data) {
                let xifechaespera1 = res.data
                this.setState({
                    ifechaespera1:xifechaespera1,
                })
            }else{
                this.setState({
                    ifechaespera1:[],
                })
            }
        })
    } 
    
    handleClose = () => {
        this.setState({ mensajealerta: false })
      }
    

    componentDidMount(){
        this.buscarpacientesespera();
    }

    render(){
        return(
            <div className="cont-principal-espera row">
                <div className="cont-espera col-12">

                    {this.state.mensajealerta === true &&
                        <Alert
                        tituloMensaje={this.state.tituloM}
                        cuerpoMensaje={this.state.cuerpoM}
                        open={this.state.mensajealerta}
                        handleClose={this.handleClose}
                        />
                    }
                    <div className="cont-titulo-espera row">
                        <div className="col-12">
                            <span className="tituloespera">Asignar orden de llegada a la lista de espera.</span>
                        </div>
                       <div className="cont-datos-espera col-12">
                            <div className="row">
                                <div className="cont-cedula col-3">
                                    <label className="opcion">Cedula.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="cedula"
                                        id="cedula"
                                         autoComplete="off"
                                        value={this.state.cedula}
                                        readOnly={true}
                                        onChange={this.onChange.bind(this)}
                                       
                                    />
                                </div>
                                <div className="cont-cedula col-5">
                                    <label className="opcion">Nombre.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nombre"
                                        id="nombre"
                                        autoComplete="off"
                                        value={this.state.nombre}
                                        readOnly={true}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="cont-motivo-consulta row">
                                
                                <div className="cont-motivo col-5">
                                    <label className="opcion">Motivo Consulta.</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="motivo"
                                        id="motivo"
                                        autoComplete="off"
                                        value={this.state.motivo}
                                        readOnly={true}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </div>
                                <div className="cont-fechano col-2">
                                    <label className="opcionno">Fecha.</label>
                                    <input  
                                        className="form-control"  
                                        name="fechaesperano"
                                        id="fechaesperano"
                                        type="text" 
                                        value={ moment(this.state.fechaespera).format("DD-MM-YYYY") }  
                                        disabled={true}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </div>
                                <div className="cont-motivo-no col-1">
                                    <label className="opcionno">Paci.No.</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="ordendellegada"
                                        id="ordendellegada"
                                        autoComplete="off"
                                        value={this.state.ordendellegada}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </div>
                                <div className="acciones col-4">
                                    <button className="boton" onClick={this.guardar.bind(this)}>Actualizar</button>
                                    <button className="boton" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                    <Link to="/"><button className="botonmedi">Salir</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cont-principal-tablas col-12">
                    <div className="row">
                        <div className="cont-general-citas col-9">
                            <span className="cont-span-cita">Citas a el día de hoy</span>
                            <div className="listadocasos">
                                <table className="tablaespera1">
                                    <thead>
                                        <tr>
                                            <th className="tituevo1">Nombre</th>
                                            <th className="tituevo1">Motivo</th>
                                            <th className="tituevo1">No.</th>
                                            <th className="tituevo1"><span className='cerrarme4'></span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="cuerpoespera1">
                                        
                                        {this.state.ifechaespera1.map((item, i) => {
                                            //console.log("paso "+i);
            
                                            return <tr className={i%2 === 0 ? "odd" : ""} key={item.variable1}><td width="150" className="itemtabla1" key={item.variable1}>{item.variable2}</td>
                                                <td width="250" className="itemtabla1">{item.variable3}</td>                               
                                                <td width="10" className="itemtabla1">{item.variable4}</td>                               
                                                <td width="20"><span className='cerrarme5' onClick={this.consultar.bind(this,item.variable1)}></span> </td></tr>                                

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CitasAction; 
import React, { Component } from 'react';

//componentes
import HorasAction from './horas'

class Horas extends Component {

    render(){
        return(
            <div>
                <HorasAction />
            </div>
        );
    }

}
export default Horas;